class SkillCard
    constructor: (el) ->
        @.$el = el
        @.hasBack = @.$el.classList.contains 'skill-card--back'

        if @.hasBack
            @.InitEvents()

        @.$el.classList.add 'skill-card--binded'

    InitEvents: ->
        self = @
        @.$el.addEventListener 'click', (e) ->
            self.$el.classList.toggle 'skill-card--active'
            return
        return

BindSkillCards = ->
    document.querySelectorAll '.skill-card:not(.skill-card--binded)'
        .forEach (el) ->
            new SkillCard el
            return
    return

BindSkillCards()

window.addEventListener 'dynamicAppendedContent', (e) ->
    BindSkillCards()
    return