class TextImageSlider
    constructor: (el) ->
        @.$el = el
        @.$slides = @.$el.querySelectorAll '.text-image-slider__slide'
        @.$textEl = @.$el.querySelector '.text-image-slider__text'
        @.$controlLeft = @.$el.querySelector '.text-image-slider--control-left'
        @.$controlRight = @.$el.querySelector '.text-image-slider--control-right'
        @.activeSlide = 0
        @.touchStart = undefined
        @.touchMove = undefined

        @.InitEvents()

        @.$el.classList.add 'text-image-slider--binded'
    
    InitEvents: ->
        @.$slides[0].classList.add 'text-image-slider__slide--active'
        self = @
        # CLICK
        @.$controlLeft.addEventListener 'click', (e) ->
            self.Slide "prev"
            return
        @.$controlRight.addEventListener 'click', (e) ->
            self.Slide "next"
            return

        # KEYS
        @.$el.addEventListener 'keyup', (e) ->
            if e.key == "ArrowLeft"
                self.Slide "prev"
            if e.key == "ArrowRight"
                self.Slide "next"
            return

        # TOUCH
        @.$el.addEventListener 'touchstart', (e) ->
            self.touchStart = {
                x: e.touches[0].clientX,
                y: e.touches[0].clientY
            }
            return
        @.$el.addEventListener 'touchmove', (e) ->
            self.touchMove = {
                x: e.touches[0].clientX,
                y: e.touches[0].clientY
            }
            return
        @.$el.addEventListener 'touchend', (e) ->
            # check if variables are set
            if self.touchStart && self.touchMove
                # check if movement on y is not too far
                yDiff = self.touchStart.y - self.touchMove.y
                if Math.abs(yDiff) < self.$el.offsetHeight * .5
                    # check minimum x movement
                    xDiff = self.touchStart.x - self.touchMove.x
                    if Math.abs(xDiff) > window.innerWidth * .4
                        # check direction
                        if xDiff > 0
                            self.Slide "next"
                        else
                            self.Slide "prev"

            self.touchStart = undefined
            self.touchMove = undefined
            return
        return

    Slide: (direction) ->
        @.$slides[@.activeSlide].classList.remove 'text-image-slider__slide--active'
        if direction == "next"
            @.activeSlide = if @.activeSlide + 1 > @.$slides.length - 1 then 0 else @.activeSlide+1
        else
            @.activeSlide = if @.activeSlide - 1 < 0 then @.$slides.length - 1 else @.activeSlide-1
        @.$textEl.innerHTML = ""
        @.$textEl.appendChild document.importNode(@.$slides[@.activeSlide].querySelector('template').content, true)
        @.$slides[@.activeSlide].classList.add 'text-image-slider__slide--active'
        self = @
        @.$slides.forEach (slideEl) ->
            if self.activeSlide != 0
                slideEl.style.transform = 'translateX(calc(-' + (self.activeSlide * 100) + '% - ' + self.activeSlide * 5 + 'rem))'
            else
                slideEl.style.transform = 'none'

BindTextImageSliders = ->
    document.querySelectorAll '.text-image-slider'
        .forEach (el) ->
            new TextImageSlider el
            return
    return

BindTextImageSliders()

window.addEventListener 'dynamicAppendedContent', (e) ->
    BindTextImageSliders()
    return