class AnimatedSlots
    constructor: (el) ->
        @.$el = el
        @.$slots = @.$el.querySelectorAll '.animated-slots__slot'
        @.$slot1 = {
            animationInterval: undefined,
            elem: @.$el.querySelector('.animated-slots__slot1'),
            entries: @.$el.querySelectorAll('.animated-slots__slot1 .animated-slots__slot-entry,.animated-slots__slot1 .animated-slots__slot-entry-filler'),
            passedTime: 0,
            result: 2,
            step: 0,
            tick: 100
        }
        @.$slot2 = {
            animationInterval: undefined,
            elem: @.$el.querySelector('.animated-slots__slot2'),
            entries: @.$el.querySelectorAll('.animated-slots__slot2 .animated-slots__slot-entry,.animated-slots__slot2 .animated-slots__slot-entry-filler'),
            passedTime: 0,
            result: 2,
            step: 0,
            tick: 100
        }
        @.$slot3 = {
            animationInterval: undefined,
            elem: @.$el.querySelector('.animated-slots__slot3'),
            entries: @.$el.querySelectorAll('.animated-slots__slot3 .animated-slots__slot-entry,.animated-slots__slot3 .animated-slots__slot-entry-filler'),
            passedTime: 0,
            result: 2,
            step: 0,
            tick: 100
        }
        @.autoAnimate = @.$el.classList.contains 'animated-slots--auto'
        @.autoAnimationInterval = undefined
        @.autoAnimationDelay = parseInt(@.$el.dataset.autoAnimationTime, 10)
        @.minimalAnimationTime = if @.autoAnimate then @.autoAnimationDelay/3 else 1000
        @.slotAnimationTickMin = 75
        @.slotAnimationTickMax = 300
        @.windowResizeInterval = undefined
        @.windowResizeDelay = 300

        @.CheckFontsize()
        @.InitEvents()
        @.$el.classList.add 'binded'

    InitEvents: ->
        self = @
        if @.autoAnimate
            @.AutoAnimate()
        else
            @.$el.addEventListener 'click', (e) ->
                self.AnimateSlots()
        window.addEventListener 'resize', (e) ->
            window.clearInterval @.windowResizeInterval
            @.windowResizeInterval = window.setInterval ->
                window.clearInterval @.windowResizeInterval
                self.CheckFontsize()
            , @.windowResizeDelay

    AnimateSlot: (slot) ->
        slot.entries.forEach (slotEntry) ->
                slotEntry.style = 'transform: translateY(calc(' + (-slot.step * 100) + '% - ' + (.4 * slot.step) + 'em));'
        if slot.step + 1 > slot.entries.length-4
            slot.step = -1
            slot.elem.classList.add '.animated-slots__slot--no-transition'
        else 
            slot.step += 1
            slot.elem.classList.remove '.animated-slots__slot--no-transition'
        slot.passedTime += slot.tick
        if slot.passedTime >= @.minimalAnimationTime
            slot.elem.classList.add '.animated-slots__slot--slow-transition'
            if slot.step == slot.result
                window.clearInterval slot.animationInterval


    AnimateSlots: ->
        self = @
        @.PrepareSlots()
        window.clearInterval @.$slot1.animationInterval
        @.$slot1.animationInterval = window.setInterval ->
            self.AnimateSlot self.$slot1
        , @.$slot1.tick

        window.clearInterval @.$slot2.animationInterval
        @.$slot2.animationInterval = window.setInterval ->
            self.AnimateSlot self.$slot2
        , @.$slot2.tick

        window.clearInterval @.$slot3.animationInterval
        @.$slot3.animationInterval = window.setInterval ->
            self.AnimateSlot self.$slot3
        , @.$slot3.tick

    AutoAnimate: ->
        self = @
        @.AnimateSlots()
        window.clearInterval @.autoAnimationInterval
        @.autoAnimationInterval = window.setInterval ->
            self.AnimateSlots()
        , @.autoAnimationDelay

    CheckFontsize: ->
        fontSize = Math.floor (@.$slots[0].getBoundingClientRect().width *.23 / 10)
        @.$el.style = 'font-size:' + fontSize + 'rem;'
        @.$el.querySelectorAll('.animated-slots__slot-entry, .animated-slots__slot-entry-filler').forEach (slotEntry) ->
            slotEntryText = slotEntry.querySelector('span')
            slotEntryText.style = 'left: 0; overflow: visible; position: absolute; top: 0; width: auto;'
            slotEntryTextWidth = slotEntryText.getBoundingClientRect().width
            fontSize = .95
            if slotEntryTextWidth > slotEntry.getBoundingClientRect().width * .9
                slotEntryText.style = 'hyphens: auto; -moz-hyphens: auto; -webkit-hyphens: auto;'
            else
                slotEntryText.removeAttribute 'style'
            while slotEntryTextWidth > slotEntry.getBoundingClientRect().width * .9
                slotEntryText.style = 'font-size:' + fontSize + 'em'
                slotEntryTextWidth = slotEntryText.getBoundingClientRect().width
                fontSize -= .05
            

    PrepareSlots: ->
        @.$slot1.elem.classList.remove '.animated-slots__slot--slow-transition'
        @.$slot1.passedTime = 0
        @.$slot1.result = window.appUtils.RandomFromRange -1, @.$slot1.entries.length-3
        @.$slot1.step = 0
        @.$slot1.tick = window.appUtils.RandomFromRange @.slotAnimationTickMin, @.slotAnimationTickMax
        
        @.$slot2.elem.classList.remove '.animated-slots__slot--slow-transition'
        @.$slot2.passedTime = 0
        @.$slot2.result = window.appUtils.RandomFromRange -1, @.$slot2.entries.length-3
        @.$slot2.step = 0
        @.$slot2.tick = window.appUtils.RandomFromRange @.slotAnimationTickMin, @.slotAnimationTickMax
        
        @.$slot3.elem.classList.remove '.animated-slots__slot--slow-transition'
        @.$slot3.passedTime = 0
        @.$slot3.result = window.appUtils.RandomFromRange -1, @.$slot3.entries.length-3
        @.$slot3.step = 0
        @.$slot3.tick = window.appUtils.RandomFromRange @.slotAnimationTickMin, @.slotAnimationTickMax
        

BindAnimatedSlots = ->
    document.querySelectorAll '.animated-slots:not(.binded)'
        .forEach (el) ->
            new AnimatedSlots el

BindAnimatedSlots()

window.addEventListener 'dynamicAppendedContent', (e) ->
    BindAnimatedSlots()