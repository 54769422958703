class CasesList
    constructor: (el) ->
        @.$el = el
        @.$cases = @.$el.querySelectorAll '.cases-list-entry'
        @.$controlLeft = @.$el.querySelector '.cases-list--control-left'
        @.$controlRight = @.$el.querySelector '.cases-list--control-right'
        @.activeCase = 0
        @.touchStart = undefined
        @.touchMove = undefined

        @.InitEvents()
        @.$el.classList.add 'cases-list--binded'

    InitEvents: ->
        @.$cases[0].classList.add 'cases-list-entry--active'
        self = @
        # CLICK
        @.$controlLeft.addEventListener 'click', (e) ->
            self.Slide "prev"
            return
        @.$controlRight.addEventListener 'click', (e) ->
            self.Slide "next"
            return

        # KEYS
        @.$el.addEventListener 'keyup', (e) ->
            if e.key == "ArrowLeft"
                self.Slide "prev"
            if e.key == "ArrowRight"
                self.Slide "next"
            return

        # TOUCH
        @.$el.addEventListener 'touchstart', (e) ->
            self.touchStart = {
                x: e.touches[0].clientX,
                y: e.touches[0].clientY
            }
            return
        @.$el.addEventListener 'touchmove', (e) ->
            self.touchMove = {
                x: e.touches[0].clientX,
                y: e.touches[0].clientY
            }
            return
        @.$el.addEventListener 'touchend', (e) ->
            # check if variables are set
            if self.touchStart && self.touchMove
                # check if movement on y is not too far
                yDiff = self.touchStart.y - self.touchMove.y
                if Math.abs(yDiff) < self.$el.offsetHeight * .5
                    # check minimum x movement
                    xDiff = self.touchStart.x - self.touchMove.x
                    if Math.abs(xDiff) > window.innerWidth * .4
                        # check direction
                        if xDiff > 0
                            self.Slide "next"
                        else
                            self.Slide "prev"

            self.touchStart = undefined
            self.touchMove = undefined
            return
        return

    Slide: (direction) ->
        @.$cases[@.activeCase].classList.remove 'cases-list-entry--active'
        if direction == "next"
            @.activeCase = if @.activeCase + 1 > @.$cases.length - 1 then 0 else @.activeCase+1
        else
            @.activeCase = if @.activeCase - 1 < 0 then @.$cases.length - 1 else @.activeCase-1
        @.$cases[@.activeCase].classList.add 'cases-list-entry--active'
        self = @
        @.$cases.forEach (caseEl) ->
            if self.activeCase != 0
                caseEl.style.transform = 'translateX(calc(-' + (self.activeCase * 100) + '% - ' + self.activeCase * 5 + 'rem))'
            else
                caseEl.style.transform = 'none'
            

BindCasesLists = ->
    document.querySelectorAll '.cases-list:not(.cases-list--binded)'
        .forEach (el) ->
            new CasesList el

BindCasesLists()

window.addEventListener 'dynamicAppendedContent', (e) ->
    BindCasesLists()