Masonry = require "isotope-layout"
import MasonryHorizontal from "isotope-masonry-horizontal"

class FeaturedCase
    constructor: (el) ->
        @.$el = el
        @.$controlLeft = @.$el.querySelector '.featured-case--control-left'
        @.$controlRight = @.$el.querySelector '.featured-case--control-right'
        @.$imagesSlide = @.$el.querySelector '.featured-case__images-slide'
        @.$images = @.$imagesSlide.querySelectorAll '.featured-case__image-slide'
        @.$slider = @.$el.querySelector '.featured-case__slides'
        
        @.activeSlide = 0
        @.imagesSlideTransform = 0
        @.masonry = undefined
        @.maxSlides = 0
        @.slideDelay = 300
        @.slideInterval = undefined
        @.touchStart = undefined
        @.touchMove = undefined
        @.windowResizeDelay = 300
        @.windowResizeInterval = undefined


        @.SetMaxSlides()
        @.CalcImagesSlideSize()
        @.BindMasonry()
        @.CheckImagesBlur()
        @.SetMaxSlides()
        @.InitEvents()
        @.$el.classList.add 'binded'

    BindMasonry: ->
        @.masonry = new Masonry( @.$imagesSlide, {
            getSortData: {
                number: '[data-order] parseInt'
            },
            layoutMode: 'masonryHorizontal',
            itemSelector: '.featured-case__image-slide',
            masonryHorizontal: {
                gutter: '.featured-case__images-slide__grid-gutter'
            },
            percentPosition: false,
            sortAscending: true,
            sortBy : 'number',
            stamp: '.featured-case__images-slide__spacer'
        })

    CalcImagesSlideSize: ->
        @.$images.forEach (image) ->
            imageImg = image.querySelector '.image__img'
            width = ((image.offsetHeight / parseInt(imageImg.getAttribute('height'),10)) * parseInt(imageImg.getAttribute('width'),10)).toFixed(0)
            image.style.width = width + 'px'
    
    CheckImagesBlur: ->
        allVisible = true
        @.$images.forEach (image) ->
            rect = image.getBoundingClientRect()
            if rect.right <= window.innerWidth || (rect.left < window.innerWidth && rect.width >= window.innerWidth)
                image.classList.add 'featured-case__image-slide--active'
            else
                allVisible = false
                image.classList.remove 'featured-case__image-slide--active'
            if rect.left < 0
                image.classList.remove 'featured-case__image-slide--active'
        if allVisible && @.activeSlide == 0
            @.$el.classList.add 'featured-case--not-slideable'
        else
            @.$el.classList.remove 'featured-case--not-slideable'

    InitEvents: ->
        self = @
        # WINDOW RESIZE
        window.addEventListener 'resize', (e) ->
            window.clearInterval self.windowResizeInterval
            self.windowResizeInterval = window.setInterval ->
                window.clearInterval self.windowResizeInterval
                self.SetMaxSlides()
                self.CalcImagesSlideSize()
                self.masonry.layout()
                self.SlideTo(0)
            , self.windowResizeInterval
        
        # CLICK
        @.$controlLeft.addEventListener 'click', (e) ->
            self.Slide "prev"
        @.$controlRight.addEventListener 'click', (e) ->
            self.Slide "next"
        
        # KEYS
        @.$el.addEventListener 'keyup', (e) ->
            if e.key == "ArrowLeft"
                self.Slide "prev"
            if e.key == "ArrowRight"
                self.Slide "next"

        # TOUCH
        @.$el.addEventListener 'touchstart', (e) ->
            self.touchStart = {
                x: e.touches[0].clientX,
                y: e.touches[0].clientY
            }
        @.$el.addEventListener 'touchmove', (e) ->
            self.touchMove = {
                x: e.touches[0].clientX,
                y: e.touches[0].clientY
            }
        @.$el.addEventListener 'touchend', (e) ->
            # check if variables are set
            if self.touchStart && self.touchMove
                # check if movement on y is not too far
                yDiff = self.touchStart.y - self.touchMove.y
                if Math.abs(yDiff) < self.$el.offsetHeight * .5
                    # check minimum x movement
                    xDiff = self.touchStart.x - self.touchMove.x
                    if Math.abs(xDiff) > window.innerWidth * .4
                        # check direction
                        if xDiff > 0
                            self.Slide "next"
                        else
                            self.Slide "prev"
    
    SetMaxSlides: ->
        @.maxSlides = if window.innerWidth > 768 then Math.ceil(@.$images.length / 2) + 1 else @.$images.length + 1
        if window.innerWidth > 768
            rect = @.$imagesSlide.getBoundingClientRect()
            if @.$imagesSlide.style.width
                necessaryLeftSlides = Math.ceil((parseInt(@.$imagesSlide.style.width, 10) - rect.left) / (window.innerWidth * (if window.innerWidth > 768 then .4 else .6)))
                @.maxSlides = necessaryLeftSlides
            

    Slide: (direction) ->
        if direction == "next"
            @.SlideTo @.activeSlide + 1
        if direction == "prev"
            @.SlideTo @.activeSlide - 1
        
    SlideTo: (slide) ->
        maxSlides = if window.innerWidth > 768 then @.maxSlides else @.maxSlides - 1
        if slide > maxSlides
            @.activeSlide = 0
        else if slide < 0
            @.activeSlide = maxSlides
        else
            @.activeSlide = slide
        if window.innerWidth > 768
            @.$slider.style.transform = "translateX(-" + (40 * @.activeSlide) + "vw)"
        else
            if @.activeSlide == 0
                @.imagesSlideTransform = 0
            nextImage = Array.from(@.$images)[@.activeSlide - 1]
            if @.activeSlide >= @.$images.length
                nextImage = @.$images[@.$images.length - 1]
            if nextImage
                @.imagesSlideTransform += window.innerWidth - (window.innerWidth - nextImage.getBoundingClientRect().left) - 1
                nextImage.classList.add '.featured-case__image-slide--active'
            else
                @.imagesSlideTransform = 0
                @.activeSlide = 0
            @.$slider.style.transform = "translateX(-" + @.imagesSlideTransform + "px)"
        self = @
        window.clearInterval @.slideInterval
        @.slideInterval = window.setInterval ->
            window.clearInterval self.slideInterval
            self.CheckImagesBlur()
        , @.slideDelay
        

BindFeaturedCases = ->
    document.querySelectorAll '.featured-case:not(.binded)'
        .forEach (el) ->
            new FeaturedCase el

BindFeaturedCases()

window.addEventListener 'dynamicAppendedContent', (e) ->
    BindFeaturedCases()