import SimpleBar from "simplebar"
class ElcCookieConsent
    constructor: (el) ->
        @.$el = el
        @.$scrollBar = undefined

        @.Init()
        @.$el.classList.add 'binded'

    Init: ->
        @.$scrollBar = new SimpleBar @.$el, { autoHide: false }

BindElcCookieConsents = ->
    document.querySelectorAll '.elc-cookie-consent:not(.binded)'
        .forEach (el) ->
            new ElcCookieConsent el

BindElcCookieConsents()