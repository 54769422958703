class ScrollImages
    constructor: (el) ->
        @.$el = el
        @.$trackEl = @.$el.querySelector '.scroll-images__track'
        
        @.lastScroll = 0
        @.malus = .1
        @.transform = 0

        @.ScrollImages()
        @.InitEvents()
        @.$el.classList.add 'scroll-images--binded'

    InitEvents: ->
        self = @
        document.addEventListener 'scroll', (e) ->
            self.ScrollImages(e)
            return
        return
    
    ScrollImages: (e) ->
        { top, left, height, width } = @.$el.getBoundingClientRect()
        scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        direction = if scrollTop > @.lastScroll then -1 else 1
        if top > height * -1 && top <= scrollTop + window.innerHeight
            @.transform += direction
        else
            @.transform = 0
        
        @.$trackEl.style.transform = 'translateX(' + (@.transform * @.malus ) + '%)'
        
        @.lastScroll = scrollTop
        return


BindScrollImages = ->
    document.querySelectorAll '.scroll-images:not(.scroll-images--binded)'
        .forEach (el) ->
            new ScrollImages el
            return
    return

BindScrollImages()

window.addEventListener 'dynamicAppendedContent', (e) ->
    BindScrollImages()