class NumberedSlider
    constructor: (el) ->
        @.$el = el
        @.$slides = @.$el.querySelectorAll '.numbered-slide'
        @.$controlLeft = @.$el.querySelector '.numbered-slider--control-left'
        @.$controlRight = @.$el.querySelector '.numbered-slider--control-right'
        @.activeSlide = 0
        @.touchStart = undefined
        @.touchMove = undefined

        @.InitEvents()
        @.$el.classList.add 'numbered-slider--binded'

    InitEvents: ->
        @.$slides[0].classList.add 'numbered-slide--active'
        self = @
        # CLICK
        @.$controlLeft.addEventListener 'click', (e) ->
            self.Slide "prev"
        @.$controlRight.addEventListener 'click', (e) ->
            self.Slide "next"

        # KEYS
        @.$el.addEventListener 'keyup', (e) ->
            if e.key == "ArrowLeft"
                self.Slide "prev"
            if e.key == "ArrowRight"
                self.Slide "next"

        # TOUCH
        @.$el.addEventListener 'touchstart', (e) ->
            self.touchStart = {
                x: e.touches[0].clientX,
                y: e.touches[0].clientY
            }
        @.$el.addEventListener 'touchmove', (e) ->
            self.touchMove = {
                x: e.touches[0].clientX,
                y: e.touches[0].clientY
            }
        @.$el.addEventListener 'touchend', (e) ->
            # check if variables are set
            if self.touchStart && self.touchMove
                # check if movement on y is not too far
                yDiff = self.touchStart.y - self.touchMove.y
                if Math.abs(yDiff) < self.$el.offsetHeight * .5
                    # check minimum x movement
                    xDiff = self.touchStart.x - self.touchMove.x
                    if Math.abs(xDiff) > window.innerWidth * .4
                        # check direction
                        if xDiff > 0
                            self.Slide "next"
                        else
                            self.Slide "prev"


            self.touchStart = undefined
            self.touchMove = undefined

    Slide: (direction) ->
        @.$slides[@.activeSlide].classList.remove 'numbered-slide--active'
        if direction == "next"
            @.activeSlide = if @.activeSlide + 1 > @.$slides.length - 1 then 0 else @.activeSlide+1
        else
            @.activeSlide = if @.activeSlide - 1 < 0 then @.$slides.length - 1 else @.activeSlide-1
        @.$slides[@.activeSlide].classList.add 'numbered-slide--active'
        self = @
        @.$slides.forEach (slide) ->
            slide.style.transform = 'translateX(calc(-' + (self.activeSlide * 100) + '% - ' + self.activeSlide * 5 + 'rem))'

BindNumberedSliders = ->
    document.querySelectorAll '.numbered-slider:not(.numbered-slider--binded)'
        .forEach (el) ->
            new NumberedSlider el
            return

BindNumberedSliders()

window.addEventListener 'dynamicAppendedContent', (e) ->
    BindNumberedSliders()
    return