class BurgerButton
    constructor: (el) ->
        @.$el = el

        @.InitEvents()
        @.$el.classList.add 'binded'

    InitEvents: ->
        self = @
        @.$el.addEventListener 'click', (e) ->
            self.$el.classList.toggle 'burger-button--open'

document.querySelectorAll '.burger-button:not(.binded)'
    .forEach (el) ->
        new BurgerButton el