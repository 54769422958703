Masonry = require "masonry-layout"
import SimpleBar from "simplebar"

class OfficesOverview
    constructor: (el) ->
        @.$el = el
        @.$closer = @.$el.querySelector '.offices-overview__closer'
        @.$filters = @.$el.querySelectorAll '.offices-overview__filter'
        @.$filtersList = @.$el.querySelector '.offices-overview__header-filter-list'
        @.$grid = @.$el.querySelector '.offices-overview__offices__masonry'
        @.$masonryElements = @.$el.querySelectorAll '.offices-overview__element'
        @.$officeInfoContainer = @.$el.querySelector '.offices-overview__office-info-container'
        @.$officesList = @.$el.querySelector '.offices-overview__offices'

        @.activeFilter = undefined
        @.masonry = undefined
        @.filterDelay = 300
        @.filterInterval = undefined
        @.filterScrollbar = undefined
        @.officesScrollbar = undefined
        @.params = window.appUtils.GetUrlParams()
        @.singleOffice = @.$el.classList.contains 'offices-overview--single'
        @.windowResizeInterval = undefined
        @.windowResizeDelay = 300
        

        @.InitEvents()
        @.BindOfficesScrollbar()
        @.CheckForMasonry()
        @.CheckForAutoOpen()
        @.$el.classList.add 'binded'

    InitEvents: ->
        self = @
        @.$filters.forEach (filter) ->
            filter.addEventListener 'click', (e) ->
                activeFilterElem = self.$el.querySelector '.offices-overview__filter--active'
                if activeFilterElem 
                    activeFilterElem.classList.remove 'offices-overview__filter--active'
                    if !self.singleOffice
                        window.appUtils.DeleteUrlParam 'office', true
                    self.ResetOfficesOverview()
                if @.dataset.officeId != self.activeFilter
                    self.activeFilter = @.dataset.officeId
                    @.classList.add 'offices-overview__filter--active'
                    if !self.singleOffice
                        self.PushState @.innerText
                else
                    self.activeFilter = undefined
                self.FilterOffices()
        if !@.singleOffice
            @.$closer.addEventListener 'click', (e) ->
                activeFilterElem = self.$el.querySelector '.offices-overview__filter--active'
                if activeFilterElem
                    self.activeFilter = undefined
                    window.appUtils.DeleteUrlParam 'office', true
                    activeFilterElem.classList.remove 'offices-overview__filter--active'
                    self.ResetOfficesOverview()
            window.addEventListener 'popstate', (e) ->
                self.params = window.appUtils.GetUrlParams()
                self.CheckForAutoOpen()
        else
            @.$filters[0].click()
        window.addEventListener 'resize', (e) ->
            window.clearInterval self.windowResizeInterval
            self.windowResizeInterval = window.setInterval ->
                window.clearInterval self.windowResizeInterval
                self.CheckForMasonry()
            , self.windowResizeDelay

    AppendOfficeInfo: ->
        self = @
        filteredOutElems = @.$grid.querySelectorAll '.offices-overview__representative:not([data-office-id="' + @.activeFilter + '"])'
        filteredOutElemsCloned = []
        filteredOutElems.forEach (filteredOutElem) ->
            filteredOutElemCloned = filteredOutElem.cloneNode true
            filteredOutElemCloned.removeAttribute 'style'
            filteredOutElemsCloned.push filteredOutElemCloned
            filteredOutElem.remove()
        matchingElems = @.$grid.querySelectorAll '.offices-overview__representative[data-office-id="' + @.activeFilter + '"]'            
        matchingElemsCloned = []
        matchingElems.forEach (matchingElem) ->
            matchingElemCloned = matchingElem.cloneNode true
            matchingElemCloned.removeAttribute 'style'
            matchingElemsCloned.push matchingElemCloned
            matchingElem.remove()
        if typeof @.masonry != "undefined"
            @.masonry.remove matchingElems
            @.masonry.layout()
        matchingElemsCloned.forEach (matchingElem) ->
            self.$grid.appendChild matchingElem
            if typeof self.masonry != "undefined"
                self.masonry.appended matchingElem
        officeInfoElemFragment = document.importNode(@.$grid.querySelector('template[data-office-id="' + @.activeFilter + '"]').content, true)
        officeInfoElem = officeInfoElemFragment.querySelector('.offices-overview__office-info')
        officeInfoElem.classList.add 'offices-overview__element', 'office-overview__office-info--active'
        @.$officeInfoContainer.appendChild officeInfoElem
        filteredOutElemsCloned.forEach (filteredOutElem) ->
            self.$grid.appendChild filteredOutElem
            if typeof self.masonry != "undefined"
                self.masonry.appended filteredOutElem
            filteredOutElem.classList.add 'offices-overview__representative--filtered'
        if typeof @.masonry != "undefined"
            @.masonry.layout()
        window.dispatchEvent new CustomEvent 'dynamicAppendedContent', { bubbles: true }
        @.$el.classList.add 'offices-overview--info-open'
        if typeof @.masonry == "undefined"
            @.$grid.scroll 0,0
        if !@.singleOffice
            window.clearInterval @.filterInterval
            @.filterInterval = window.setInterval ->
                window.clearInterval self.filterInterval
                self.$grid.scrollIntoView {behavior: "smooth", block: 'end'}
            , @.filterDelay

    BindMasonry: ->
        if typeof @.masonry == "undefined"
            @.masonry = new Masonry( @.$grid, {
                columnWidth: '.offices-overview__grid-sizer',
                gutter: '.offices-overview__grid-gutter',
                horizontalOrder: true,
                itemSelector: '.offices-overview__element',
                percentPosition: true,
                stamp: '.offices-overview__spacer'
            })
    
    BindFilterScrollbar: ->
        if typeof @.filterScrollbar == "undefined"
            @.filterScrollbar = new SimpleBar @.$filtersList, { autoHide: false }
    BindOfficesScrollbar: ->
        if typeof @.officesScrollbar == "undefined"
            @.officesScrollbar = new SimpleBar @.$officesList, { autoHide: false }

    CheckForAutoOpen: ->
        if @.params
            if @.params.hasOwnProperty('office')
                if @.params.office[0] != ""
                    filterElem = @.$el.querySelector('.offices-overview__filter[data-office-short="' + decodeURI(@.params.office[0]) + '"]')
                    if filterElem
                        filterElem.click()

    CheckForMasonry: ->
        if window.innerWidth <= 768
            if typeof @.masonry != "undefined"
                @.masonry.destroy()
                @.masonry = undefined
            @.BindFilterScrollbar()

        else
            @.BindMasonry()

    FilterOffices: ->
        self = @
        if typeof @.activeFilter != "undefined"
            @.AppendOfficeInfo()
            
    PushState: (shortName) ->
        if shortName && shortName != ""
            window.appUtils.PushState 'office', encodeURI(shortName)
        
    ResetOfficesOverview: ->
        self = @
        representativeElems = @.$grid.querySelectorAll '.offices-overview__representative'
        officeInfoElem = @.$officeInfoContainer.querySelector '.office-overview__office-info--active'
        if typeof @.masonry != "undefined"
            @.masonry.remove representativeElems
        else
            representativeElems.forEach (representativeElem) ->
                representativeElem.remove()
        officeInfoElem.remove()
        masonryElementsCloned = []
        @.$masonryElements.forEach (masonryElem) ->
            masonryElemCloned = masonryElem.cloneNode true
            masonryElemCloned.removeAttribute 'style'
            masonryElementsCloned.push masonryElemCloned
        @.$masonryElements = masonryElementsCloned
        @.$masonryElements.forEach (masonryElem) ->
            self.$grid.appendChild masonryElem
            if typeof self.masonry != "undefined"
                self.masonry.appended masonryElem
        if typeof @.masonry != "undefined"
            @.masonry.layout()
        @.$el.classList.remove 'offices-overview--info-open'

BindOfficesOverviews = ->
    document.querySelectorAll '.offices-overview:not(.binded)'
        .forEach (el) ->
            new OfficesOverview el

BindOfficesOverviews()

window.addEventListener 'dynamicAppendedContent', (e) ->
    BindOfficesOverviews()