class Header
    constructor: (el) ->
        @.$el = el
        @.$burgerButton = @.$el.querySelector '.header__menu-button'
        @.$links = @.$el.querySelectorAll 'a'
        @.menuOpen = false
        @.lastPageScroll = 0
    
        @.InitEvents()
        @.ToggleFixedState()
        @.$el.classList.add 'binded'

    CloseHeader: ->
        console.log "CLOSE HEADER FUNCTION"
        document.getElementById('site').classList.remove 'header-open'
        # reset page scroll
        document.getElementById('content').style.removeProperty "transform"
        window.scrollTo 0, @.pageLastScroll

    InitEvents: ->
        self = @
        @.$burgerButton.addEventListener 'click', (e) ->
            self.ToggleHeaderOpen()
        @.$links
            .forEach (link) ->
                if link.dataset.closeHeader
                    link.addEventListener 'click', (e) ->
                        self.$burgerButton.click()
                        return
                return
            
        document.addEventListener 'scroll', (e) ->
            self.ToggleFixedState()

    OpenHeader: ->
        # save page last scroll position and fix page
        @.pageLastScroll = window.scrollY
        document.getElementById('site').classList.add 'header-open'
        document.getElementById('content').style.transform = "translateY(-" + @.pageLastScroll + "px)"
    
    ToggleHeaderOpen: ->
        @.$el.classList.toggle 'header--open'
        @.$burgerButton.setAttribute 'aria-hidden', (!@.menuOpen).toString()
        @.menuOpen = @.$el.classList.contains('header--open')
        if @.menuOpen
            @.OpenHeader()
        else
            @.CloseHeader()
    
    ToggleFixedState: ->
        if window.scrollY > 35
            @.$el.classList.add 'header--fixed'
        else
            if !document.getElementById('site').classList.contains('overlay-open')
                @.$el.classList.remove 'header--fixed'

document.querySelectorAll '.header:not(.binded)'
    .forEach (el) ->
        new Header el