# SCROLL BAR CALC
class ScrollBarCalc
    constructor: ->
        @.scrollBarWidth = 0
        @.resizeDelay = 300
        @.resizeInterval = undefined

        @.SetScrollBarWidth()
        @.InitEvents()
    
    InitEvents: ->
        self = @
        window.addEventListener 'resize', (e) ->
            window.clearInterval self.resizeInterval
            self.resizeInterval = window.setInterval ->
                window.clearInterval self.resizeInterval
                self.SetScrollBarWidth()
            , self.resizeDelay

    SetScrollBarWidth: ->
        @.scrollBarWidth = window.innerWidth - document.documentElement.clientWidth
        document.documentElement.style.setProperty '--scrollbar-width', @.scrollBarWidth + 'px'
window.rsmScrollBarCalc = new ScrollBarCalc()

# --------------------------------------------------------------------------------------

# SCROLL TO ANCHOR
class ScrollToAnchor
    constructor: ->
        @.$anchorLinks = []
        @.scrollTimeout

        @.RefreshAnchorLinkList()
        @.InitEvents()

    InitEvents: ->
        self = @
        if location.hash
            window.scrollTo 0,0
            @.scrollTimeout = window.setTimeout ->
                window.scrollTo 0,0
                self.ScrollToElem document.getElementById location.hash.substr(1)
            , 100
        @.BindAnchorLinks()

    BindAnchorLinks: ->
        self = @
        @.$anchorLinks.forEach (anchorLink) ->
            if !anchorLink.classList.contains 'binded'
                anchorLink.addEventListener 'click', (e) ->
                    e.preventDefault()
                    hash = @.href.substr(@.href.indexOf("#"))
                    location.hash = hash
                    window.scrollTo 0,0
                    @.scrollTimeout = window.setTimeout ->
                        window.scrollTo 0,0
                        self.ScrollToElem document.getElementById hash.substr(1)
                anchorLink.classList.add 'binded'


    ScrollToElem: (elem) ->
        if elem
            window.scrollTo {
                behavior: 'smooth',
                left: 0,
                top: elem.getBoundingClientRect().top - (document.querySelector('.header').offsetHeight + 50)
            }
    
    RefreshAnchorLinkList: ->
        self = @
        document.querySelectorAll 'a[href^="#"]:not(.binded)'
            .forEach (anchorLink) ->
                self.$anchorLinks.push anchorLink


window.rsmScrollToAnchor = new ScrollToAnchor()
window.addEventListener 'dynamicAppendedContent', (e) ->
    window.rsmScrollToAnchor.RefreshAnchorLinkList()