class ImageOverlay
    constructor: (el) ->
        @.$el = el
        @.$closeButton = @.$el.querySelector '.image-overlay__closer'
        @.openCloseInterval = undefined
        @.closeDelay = 310
        @.openDelay = 160

        @.InitEvents()
        @.OpenImageOverlay()

        @.$el.classList.add 'binded'

    InitEvents: ->
        self = @
        @.$closeButton.addEventListener 'click', (e) ->
            e.preventDefault()
            self.CloseImageOverlay()
    
    
    CloseImageOverlay: ->
        self = @
        window.clearInterval @.openCloseInterval
        @.$el.classList.remove 'image-overlay--open'
        @.openCloseInterval = window.setInterval ->
            window.clearInterval self.openCloseInterval
            self.$el.remove()
        , @.closeDelay

    OpenImageOverlay: ->
        self = @
        @.$el.classList.add 'image-overlay--appended'
        window.clearInterval @.openCloseInterval
        @.openCloseInterval = window.setInterval ->
            window.clearInterval self.openCloseInterval
            self.$el.classList.add 'image-overlay--open'
        , @.openDelay

BindImageOverlays = ->
    document.querySelectorAll '.image-overlay__content:not(.binded)'
        .forEach (el) ->
            new ImageOverlay el

BindImageOverlays()    

window.addEventListener 'imageOverlayAppended', (e) ->
    BindImageOverlays()