class Tag
    constructor: (el) ->
        @.$el = el
    
        @.InitEvents()
        @.$el.classList.add 'binded'

    InitEvents: ->
        self = @
        @.$el.addEventListener 'click', (e) ->
            self.$el.classList.toggle 'tag--active'
            window.dispatchEvent new CustomEvent 'tagactivechange', { 
                bubble: true, 
                detail: { 
                    tagId: self.$el.dataset.tagId,
                    active: !self.$el.classList.contains('tag--active')
                } 
            }

BindTags = ->
    document.querySelectorAll '.tag:not(.binded)'
        .forEach (el) ->
            new Tag el

BindTags()

window.addEventListener 'dynamicAppendedContent', (e) ->
    BindTags()