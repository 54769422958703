class VimeoVideo
    constructor: (el) ->
        @.$el = el
        @.$playButton = @.$el.querySelector '.vimeo-video__play-button'
        @.$template = @.$el.querySelector 'template'
        @.$vimeoEmbedIframe = undefined
        @.loaded = @.$el.classList.contains 'vimeo-video--loaded'
        @.player = undefined
        @.playerScript = 'https://player.vimeo.com/api/player.js'

        @.InitEvents()
        @.$el.classList.add 'binded'
    
    InitEvents: ->
        self = @
        @.AppendPlayerScript()
        if !@.loaded
            @.$playButton.addEventListener 'click', (e) ->
                self.AppendTemplateAndLoad()
        window.addEventListener 'stopAllVimeoVideos', (e) ->
            self.PauseVideo()

    AppendPlayerScript: ->
        bindedPlayerScript = document.getElementById 'vimeo-player-script'
        if !bindedPlayerScript
            playerScript = document.createElement 'script'
            playerScript.id = 'vimeo-player-script'
            playerScript.src = @.playerScript
            document.querySelector('head').appendChild playerScript

    AppendTemplateAndLoad: ->
        self = @
        @.$template.content.querySelector('iframe').src += '&autoplay=1&api=1'
        @.$el.appendChild document.importNode(@.$template.content, true)
        @.$vimeoIframe = @.$el.querySelector 'iframe'
        @.$vimeoIframe.addEventListener 'load', (e) ->
            self.$el.classList.add 'vimeo-video--loaded'
            self.player = new Vimeo.Player self.$vimeoIframe

    PauseVideo: ->
        if typeof @.player != "undefined"
            @.player.pause()

BindVimeoVideos = ->
    document.querySelectorAll '.vimeo-video:not(.binded)'
        .forEach (el) ->
            new VimeoVideo el

BindVimeoVideos()

window.addEventListener 'dynamicAppendedContent', (e) ->
    BindVimeoVideos()