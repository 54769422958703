class PageHeroSlider
    constructor: (el) ->
        @.$el = el
        @.$slides = @.$el.querySelectorAll '.page-hero__slide'
        @.$slidesControl = @.$el.querySelector '.page-hero__slides-control'
        @.$slideIndicators = @.$el.querySelectorAll '.page-hero__slide-indicator'
        @.$site = document.getElementById 'site'
        @.$header = document.querySelector '.header'

        @.activeSlide = 1
        @.activeTheme = undefined
        @.elBounding = @.$el.getBoundingClientRect()
        @.isVisible = false
        @.mobileView = false
        @.slideWindowScrollHeight = 1.5
        @.slideWindowStandScrollHeight = .5
        @.overscrolled = false
        @.windowResizeInterval = undefined
        @.windowResizeDelay = 300

        @.InitEvents()
        @.CheckIfIsVisible()
        @.$el.classList.add 'binded'

    AnimateNextSlide: ->
        if @.isVisible
            nextSlide = if @.$slides.length > @.activeSlide then @.activeSlide + 1 else false
            if nextSlide
                nextSlideElem = @.$slides[nextSlide - 1]
                actualSlideElem = @.$slides[@.activeSlide - 1]
                
                actualSlideHeight = @.$el.offsetTop + (@.activeSlide * window.innerHeight * @.slideWindowScrollHeight)
                actualSlideStartAnimationScrollHeight = actualSlideHeight - (window.innerHeight * (@.slideWindowScrollHeight - @.slideWindowStandScrollHeight))
                if window.scrollY > actualSlideStartAnimationScrollHeight
                    scrolledThroughPercentage = ((window.scrollY - actualSlideStartAnimationScrollHeight) / (window.innerHeight * (@.slideWindowScrollHeight - @.slideWindowStandScrollHeight))) * 100
                    scrolledThroughPercentage = if scrolledThroughPercentage < 0 then 0 else if scrolledThroughPercentage > 100 then 100 else scrolledThroughPercentage
                    
                    nextSlideElem.style.clipPath = 'polygon(0% 100%, 100% 100%, 100% ' + (100 - scrolledThroughPercentage) + '%, 0 ' + (100 - scrolledThroughPercentage) + '%)'

                    nextSlideElem.classList.add 'page-hero__slide--animated'
    
    CheckIfIsVisible: ->
        @.isVisible = @.elBounding.top >= 0 || (@.elBounding.bottom - @.$header.offsetHeight) >= 0
    
    CheckForMobileView: ->
        if window.innerWidth <= 768
            @.mobileView = true
        else
            @.mobileView = false

    CheckInitialThemes: ->
        # page hero slides control
        for i in [0..@.$slidesControl.classList.length]
            if /theme--.*/.test(@.$slidesControl.classList[i])
                @.activeTheme = @.$slidesControl.classList[i]
                break
        # site
        for i in [0..@.$site.classList.length]
            if /theme--.*/.test(@.$site.classList[i])
                @.$site.dataset.theme = @.$site.classList[i]
                @.$site.classList.remove @.$site.classList[i]
                @.$site.classList.add @.activeTheme
                break
    
    GetBounding: ->
        @.elBounding = @.$el.getBoundingClientRect()

    InitEvents: ->
        self = @
        @.$el.style.cssText += 'height:' + (@.$slides.length * (@.slideWindowScrollHeight * 100)) + 'vh'
        @.CheckInitialThemes()
        @.CheckForMobileView()
        @.OverscrollCheck()
        @.SlideCheck()
        document.addEventListener 'scroll', (e) ->
            self.GetBounding()
            self.CheckIfIsVisible()
            self.OverscrollCheck() 
            self.SlideCheck()
            self.AnimateNextSlide()
        @.$el.addEventListener 'click', (e) ->
            if !self.mobileView
                if !(e.target.classList.contains 'mouse-cursor--hide')
                    self.ScrollToSlide self.activeSlide + 1
        @.$slideIndicators.forEach (slideIndicator) ->
            slideIndicator.addEventListener 'click', (e) ->
                e.preventDefault()
                self.ScrollToSlide Array.prototype.indexOf.call(self.$slideIndicators,@) + 1
        window.addEventListener 'resize', (e) ->
            window.clearInterval @.windowResizeInterval
            @.windowResizeInterval = window.setInterval ->
                window.clearInterval self.windowResizeInterval
                self.GetBounding()
                self.CheckForMobileView()
                self.AnimateNextSlide()
            , @.windowResizeDelay

    OverscrollCheck: ->
        if !self.mobileView
            if @.elBounding.top + @.elBounding.height - window.innerHeight <= 0
                @.SetOverscrolled()
            else
                @.UnsetOverscrolled()
    
    ScrollToSlide: (slide) ->
        if !@.overscrolled
            # scroll past max slides length to make scroll over slider with click in last slide possible
            if slide > 0 && slide <= @.$slides.length + 1
                window.scrollTo {
                    behavior: 'smooth',
                    left: 0,
                    top: (slide-1) * window.innerHeight * @.slideWindowScrollHeight + @.$header.offsetHeight + 10
                }
           

    SetOverscrolled: ->
        @.$el.classList.add 'page-hero--slider-overscrolled'
        @.$site.classList.remove @.activeTheme
        @.$site.classList.add @.$site.dataset.theme
        @.overscrolled = true

    SlideAnimation: ->
        activeSlideElem = @.$el.querySelector('.page-hero__slide--active')
        if activeSlideElem
            console.log "ACTIVE SLIDE ELEM"

    SlideCheck: ->
        if !@.overscrolled and !@.mobileView
            if (window.scrollY - @.$header.offsetHeight) > @.activeSlide * window.innerHeight * @.slideWindowScrollHeight
                @.SlideTo @.activeSlide + 1
            else if (window.scrollY - @.$header.offsetHeight) < ((@.activeSlide - 1) * window.innerHeight * @.slideWindowScrollHeight)
                @.SlideTo @.activeSlide - 1

    SlideTo: (slide) ->
        if slide > 0 && slide <= @.$slides.length
            @.$slides[@.activeSlide-1].classList.remove 'page-hero__slide--active'
            @.$slideIndicators[@.activeSlide-1].classList.remove 'page-hero__slide-indicator--active'
            @.activeSlide = slide
            activeSlide = @.$slides[slide - 1]
            activeSlide.classList.add 'page-hero__slide--active'
            @.$slides.forEach (slide) ->
                slide.classList.remove 'page-hero__slide--animated'
                slide.style.removeProperty 'clip-path'
            @.$slideIndicators[slide - 1].classList.add 'page-hero__slide-indicator--active'
            for i in [0..activeSlide.classList.length]
                if /theme--.*/.test(activeSlide.classList[i])
                    @.$slidesControl.classList.remove @.activeTheme
                    @.$site.classList.remove @.activeTheme
                    @.$site.classList.add activeSlide.classList[i]
                    @.$slidesControl.classList.add activeSlide.classList[i]
                    @.activeTheme = activeSlide.classList[i]
                    break
                    
    
    UnsetOverscrolled: ->
        @.$el.classList.remove 'page-hero--slider-overscrolled'
        @.$site.classList.remove @.$site.dataset.theme
        @.$site.classList.add @.activeTheme
        @.overscrolled = false

BindPageHeroSliders = ->
    document.querySelectorAll '.page-hero--slider:not(.binded)'
        .forEach (el) ->
            new PageHeroSlider el

BindPageHeroSliders()

window.addEventListener 'dynamicAppendedContent', (e) ->
    BindPageHeroSliders()