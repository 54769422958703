class MouseCursor
    constructor: (el) ->
        @.$el = el
        @.$mouseCursorAreas = undefined
        @.$mouseCursorHideElements = undefined
        @.shown = false
        @.forcedHide = false
        @.color = undefined
        @.icon = undefined

        @.InitEvents()
        @.GetMouseCursorElements()
        @.$el.classList.add 'binded'
    
    InitEvents: ->
        self = @
        # change position by the one of the mouse if mouse cursor element is shown
        document.addEventListener 'mousemove', (e) ->
            if self.shown
                self.$el.style.left = e.clientX + 'px'
                self.$el.style.top = e.clientY + 'px'
        # refresh the trigger elements list and listeners if new elements are appended to the DOM
        window.addEventListener 'dynamicAppendedContent', (e) ->
            self.GetMouseCursorElements()

    GetMouseCursorElements: ->
        # get all trigger elements and bind enter/leave listeners
        @.$mouseCursorAreas = document.querySelectorAll '.mouse-cursor--area:not(.mouse-cursor--binded)'
        self = @
        @.$mouseCursorAreas
            .forEach (mouseCursorArea) ->
                mouseCursorArea.addEventListener 'mouseover', (e) ->
                    if !self.forcedHide && !(window.innerWidth <= 768 && @.classList.contains('mouse-cursor--not-mobile'))
                        self.ShowMouseCursor(@)
                mouseCursorArea.addEventListener 'mouseleave', (e) ->
                    self.HideMouseCursor()
                mouseCursorArea.classList.add 'mouse-cursor--binded'
        @.$mouseCursorHideElements = document.querySelectorAll '.mouse-cursor--hide:not(.mouse-cursor--binded)'
            .forEach (mouseCursorElements) ->
                mouseCursorElements.addEventListener 'mouseenter', (e) ->
                    self.forcedHide = true
                    self.HideMouseCursor()
                mouseCursorElements.addEventListener 'mouseleave', (e) ->
                    self.forcedHide = false
                mouseCursorElements.classList.add 'mouse-cursor--binded'
    
    ShowMouseCursor: (el) ->
        @.$el.classList.add 'mouse-cursor--shown'
        @.shown = true
        document.getElementById('site').classList.add 'mouse-cursor-used'
        # check for icon change if an element was handed over
        if el
            # check if classlist contains 'mouse-cursor--use-....' and get the corresponding icon name if so
            for i in [0..el.classList.length]
                if /mouse-cursor--use-.*/.test(el.classList[i])
                    @.ChangeMouseCursorIcon el.classList[i].substr(18,el.classList[i].length)
                if /mouse-cursor--color-.*/.test(el.classList[i])
                    @.ChangeMouseCursorColor el.classList[i].substr(20,el.classList[i].length)

    HideMouseCursor: ->
        @.$el.classList.remove 'mouse-cursor--shown'
        @.shown = false 
        @.ResetMouseCursorIcon()
        document.getElementById('site').classList.remove 'mouse-cursor-used'

    ChangeMouseCursorColor: (color) ->
        @.ResetMouseCursorColor()
        @.color = color
        @.$el.classList.add 'mouse-cursor--' + @.color

    ResetMouseCursorColor: ->
        if @.color
            @.$el.classList.remove 'mouse-cursor--' + @.color
            @.color = undefined

    ChangeMouseCursorIcon: (icon) ->
        @.ResetMouseCursorIcon()
        @.icon = icon
        @.$el.classList.add 'mouse-cursor--' + @.icon
    ResetMouseCursorIcon: ->
        if @.icon
            @.$el.classList.remove  'mouse-cursor--' + @.icon
            @.icon = undefined

document.querySelectorAll '.mouse-cursor:not(.binded)'
    .forEach (el) ->
        new MouseCursor el