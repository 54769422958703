class Tooltip
    constructor: (el) ->
        @.$el = el
        @.forcedDirection = @.$el.classList.contains 'tooltip--forced-direction'
    
        @.InitEvents()
        @.$el.classList.add 'binded'

    InitEvents: ->
        self = @
        @.$el.addEventListener 'click', (e) ->
            e.preventDefault()
            e.stopPropagation()
            self.$el.classList.toggle 'tooltip--open'
            self.$el.setAttribute 'aria-hidden', (!self.$el.classList.contains('tooltip--open')).toString()
            self.CheckPlacement()

        window.addEventListener 'click', (e) ->
            self.CloseTooltip()
    
    CheckPlacement: ->
        if !@.forcedDirection
            boundRect = @.$el.getBoundingClientRect()
            pseudoStyle = window.getComputedStyle @.$el, ':before'
            pseudoStyleWidth = parseFloat pseudoStyle.width
            pseudoStyleHeight = parseFloat pseudoStyle.height
            if boundRect.top + boundRect.height/2 + pseudoStyleWidth/2 > window.innerHeight &&
            boundRect.left + boundRect.width/2 - pseudoStyleWidth/2 > 0 && 
            boundRect.left + boundRect.width/2 + pseudoStyleWidth/2 < window.innerWidth
                @.$el.classList.add 'tooltip--top'
            if boundRect.left + boundRect.width/2 - pseudoStyleWidth/2 < 0
                @.$el.classList.add 'tooltip--right'
            if boundRect.left + boundRect.width/2 + pseudoStyleWidth/2 > window.innerWidth
                @.$el.classList.add 'tooltip--left'

    CloseTooltip: ->
        @.$el.classList.remove 'tooltip--open'
        @.$el.setAttribute 'aria-hidden', 'true'
        
BindTooltips = ->
    document.querySelectorAll '.tooltip:not(.binded)'
        .forEach (el) ->
            new Tooltip el

BindTooltips()

window.addEventListener 'dynamicAppendedContent', (e) ->
    BindTooltips()
