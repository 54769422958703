class AccordionEntry
    constructor: (el) ->
        @.$el = el
        @.$header = @.$el.querySelector '.accordion-entry__header'

        @.InitEvents()
        @.$el.classList.add 'accordion-entry--binded'
        return

    InitEvents: ->
        self = @
        @.$header.addEventListener 'click', (e) ->
            e.preventDefault()
            self.$el.classList.toggle 'accordion-entry--open'
            return
        return

BindAccordionEntries = ->
    document.querySelectorAll '.accordion-entry:not(.accordion-entry--binded)'
        .forEach (el) ->
            new AccordionEntry el
            return
    return

BindAccordionEntries()

window.addEventListener 'dynamicAppendedContent', (e) ->
    BindAccordionEntries()
    return