class PageLoader
    constructor: (el) ->
        @.$el = el

        @.InitEvents()
        
    InitEvents: ->
        self = @
        window.addEventListener 'showpageloader', (e) ->
            self.$el.classList.add 'page-loader--shown'
        window.addEventListener 'hidepageloader', (e) ->
            self.$el.classList.remove 'page-loader--shown'
    
document.querySelectorAll '.page-loader'
    .forEach (el) ->
        new PageLoader el