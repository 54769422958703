class AnimatedCards
    constructor: (el) ->
        @.$el = el
        @.$cards = @.$el.querySelectorAll '.animated-cards__card'
        @.activeCard = 1
        @.animationInterval = undefined
        @.animationHalfInterval = undefined
        @.animationDuration = 750
        @.autoAnimate = @.$el.classList.contains 'animated-cards--auto'
        @.autoAnimationInterval = undefined
        @.autoAnimationDelay = parseInt(@.$el.dataset.autoAnimationTime, 10)
        @.maxRotationLeft = -12
        @.maxRotationRight = 12
        @.windowResizeInterval = undefined
        @.windowResizeDelay = 300

        @.RotateCardsRandom()
        @.CheckFontsize()
        @.InitEvents()
        @.$el.classList.add 'binded'

    InitEvents: ->
        self = @
        if @.autoAnimate
            @.AutoAnimate()
        else
            @.$cards.forEach (card) ->
                card.addEventListener 'click', (e) ->
                    self.AnimateCards()
        window.addEventListener 'resize', (e) ->
            window.clearInterval @.windowResizeInterval
            @.windowResizeInterval = window.setInterval ->
                window.clearInterval @.windowResizeInterval
                self.CheckFontsize()
            , @.windowResizeDelay
        

    AnimateCards: ->
        self = @
        @.$el.classList.add 'animated-cards--animation'
        @.$cards[@.activeCard-1].closest('.animated-cards__card-container').classList.add 'animated-cards__card-container--animated'
        @.activeCard = if @.activeCard+1 > @.$cards.length then 1 else @.activeCard+1
        window.clearInterval @.animationHalfInterval
        @.animationHalfInterval = window.setInterval ->
            window.clearInterval self.animationHalfInterval
            cardOrder = self.$cards.length
            self.$cards.forEach (card) ->
                cardContainer = card.closest('.animated-cards__card-container')
                if self.activeCard == 1
                    newOrder = cardOrder
                    cardOrder--
                else
                    zIndex = parseInt document.defaultView.getComputedStyle(cardContainer).getPropertyValue('z-index'), 10
                    newOrder = if zIndex + 1 > self.$cards.length || zIndex < 0 then 1 else zIndex + 1
                cardContainer.style = 'z-index:' + newOrder + ';' 
        , @.animationDuration/2

        window.clearInterval @.animationInterval
        @.animationInterval = window.setInterval ->
            window.clearInterval self.animationInterval
            self.$cards.forEach (card) ->
                card.closest('.animated-cards__card-container').classList.remove 'animated-cards__card-container--animated'
            self.$el.classList.remove 'animated-cards--animation'
        , @.animationDuration

    AutoAnimate: ->
        self = @
        window.clearInterval @.autoAnimationInterval
        @.autoAnimationInterval = window.setInterval ->
            self.AnimateCards()
        , @.autoAnimationDelay
    
    CheckFontsize: ->
        fontSize = Math.floor (@.$cards[0].getBoundingClientRect().width *.12 / 10)
        @.$el.style = 'font-size:' + fontSize + 'rem;'
        @.$cards.forEach (card) ->
            cardText = card.querySelector('span')
            cardText.style = 'left: 0; overflow: visible; position: absolute; top: 0; width: auto;'
            if cardText.getBoundingClientRect().width >= card.getBoundingClientRect().width
                cardText.style = 'hyphens: auto; -moz-hyphens: auto; -webkit-hyphens: auto;'
            else
                cardText.removeAttribute 'style'

    RotateCardsRandom: ->
        self = @
        cardOrder = @.$cards.length
        @.$cards.forEach (card) ->
            card.style = 'transform: rotate(' + window.appUtils.RandomFromRange(self.maxRotationLeft,self.maxRotationRight) + 'deg);'
            card.closest('.animated-cards__card-container').style = 'z-index: ' + cardOrder + ';'
            cardOrder--

BindAnimatedCards = ->
    document.querySelectorAll '.animated-cards:not(.binded)'
        .forEach (el) ->
            new AnimatedCards el

BindAnimatedCards()

window.addEventListener 'dynamicAppendedContent', (e) ->
    BindAnimatedCards()