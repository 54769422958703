class NumberText
    constructor: (el) ->
        @.$el = el
        @.$numberEl = @.$el.querySelector '.number-text__number'

        @.actualNumber = 0
        @.animated = @.$el.classList.contains 'number-text--animated'
        @.animationFinished = false
        @.animationInterval = undefined
        @.animationStarted = false
        @.animationTime = @.$el.dataset.animationTime || 300
        @.increment = @.$el.dataset.increment || false
        @.intersectionObserver = undefined
        @.targetNumber = parseFloat @.$numberEl.innerText

        if @.animated
            @.animationTime = parseInt @.animationTime, 10
            @.increment = parseInt @.increment, 10
            @.$numberEl.innerHTML = @.actualNumber
            @.Observe()

        @.$el.classList.add 'number-text--binded'
        return

    AnimateNumber: ->
        self = @
        @.animationStarted = true
        @.animationInterval = window.setInterval ->
            self.actualNumber += self.increment
            if self.actualNumber > self.targetNumber
                self.actualNumber = self.targetNumber
                self.animationFinished = true
                window.clearInterval self.animationInterval
            self.$numberEl.innerHTML = self.actualNumber
        , @.animationTime/ (@.targetNumber / @.increment)
        return
    
    Observe: ->
        self = @
        options = {
            rootMargin: '0px'
            threshold: 1.0
        }
        callback = (entries, observer) ->
            entries.forEach (entry) ->
                if entry.isIntersecting
                    self.AnimateNumber()
                    self.intersectionObserver.unobserve self.$el
                    self.intersectionObserver = null
                return
            return

        @.intersectionObserver = new IntersectionObserver callback, options
        @.intersectionObserver.observe @.$el
        return




BindNumberTexts = ->
    document.querySelectorAll '.number-text:not(.number-text--binded)'
        .forEach (el) ->
            new NumberText el
            return
    return

BindNumberTexts()

window.addEventListener 'dynamicAppendedContent', (e) ->
    BindNumberTexts()
    return