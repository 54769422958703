class YtVideo
    constructor: (el) ->
        @.$el = el
        @.$playButton = @.$el.querySelector '.yt-video__play-button'
        @.$template = @.$el.querySelector 'template'
        @.$ytIframe = undefined
        @.loaded = @.$el.classList.contains 'yt-video--loaded'

        @.InitEvents()
        @.$el.classList.add 'binded'
    
    InitEvents: ->
        self = @
        if !@.loaded
            @.$playButton.addEventListener 'click', (e) ->
                self.AppendTemplateAndLoad()
            
        window.addEventListener 'stopAllYtVideos', (e) ->
            self.PauseVideo()

    AppendTemplateAndLoad: ->
        @.$el.classList.add 'yt-video--loaded'
        @.loaded = true
        @.$el.appendChild document.importNode(@.$template.content, true)
        @.$ytIframe = @.$el.querySelector 'iframe'

    PauseVideo: ->
        if typeof @.$ytIframe != "undefined"
            @.$ytIframe.contentWindow.postMessage '{"event":"command","func":"stopVideo","args":""}', '*'

BindYtVideos = ->
    document.querySelectorAll '.yt-video:not(.binded)'
        .forEach (el) ->
            new YtVideo el

BindYtVideos()

window.addEventListener 'dynamicAppendedContent', (e) ->
    BindYtVideos()