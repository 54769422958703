Masonry = require "masonry-layout"

class CasesOverview
    constructor: (el) ->
        @.$el = el
        @.$caseSheets = @.$el.querySelectorAll '.case-sheet'
        @.$grid = @.$el.querySelector '.cases-overview__cases-list'
        @.$tags = @.$el.querySelectorAll '.cases-overview__tag'
        @.activeTags = []
        @.hideTagsMobile = @.$el.classList.contains 'cases-overview--hide-tags'
        @.masonry = undefined
        @.resetFiltersFired = false

        @.BindMasonry()
        @.InitEvents()
        @.$el.classList.add 'binded'
    
    BindMasonry: ->
        @.masonry = new Masonry( @.$grid, {
            columnWidth: '.cases-overview__grid-sizer',
            gutter: '.cases-overview__grid-gutter',
            itemSelector: '.cases-overview__case',
            percentPosition: true,
            stamp: '.cases-overview__spacer'
        })

    InitEvents: ->
        self = @
        @.$tags.forEach (tag) ->
            tag.addEventListener 'click', (e) ->
                if @.classList.contains 'tag--active'
                    self.activeTags.push @.dataset.tagId
                else
                    self.activeTags.splice(self.activeTags.indexOf(@.dataset.tagid),1)
                self.FilterCases()
        # reset filters if tags hidden on mobile if mobile view is reached
        if @.hideTagsMobile
            window.addEventListener 'resize', (e) ->
                if window.innerWidth <= 768
                    if !self.resetFiltersFired
                        self.ResetFilters()
                else
                    self.resetFiltersFired = false
    FilterCases: ->
        self = @
        @.$caseSheets.forEach (caseSheet) ->
            if self.activeTags.length < 1
                caseSheet.classList.remove 'case-sheet--hidden'
            else
                caseSheetTags = caseSheet.dataset.tags.split(",")
                hasTag = false
                for caseSheetTag in caseSheetTags
                    if self.activeTags.indexOf(caseSheetTag) > -1
                        hasTag = true
                if hasTag
                    caseSheet.classList.remove 'case-sheet--hidden'
                else
                    caseSheet.classList.add 'case-sheet--hidden'
            self.masonry.destroy()
            self.BindMasonry()
    ResetFilters: ->
        @.activeTags = []
        @.$tags.forEach (tag) ->
            tag.classList.remove 'tag--active'
        @.FilterCases()
        @.resetFiltersFired = true
            

BindCasesOverviews = ->
    document.querySelectorAll '.cases-overview:not(.binded)'
        .forEach (el) ->
            new CasesOverview el

BindCasesOverviews()

window.addEventListener 'dynamicAppendedContent', (e) ->
    BindCasesOverviews()