class Milkbox
    constructor: (el) ->
        @.$el = el
        
        @.rotation = 0
        @.shown = false

        @.Init()
        @.$el.classList.add 'binded'
    
    Hide: ->
        @.$el.classList.remove 'milkbox--show-faces'
        @.shown = false

    Init: ->
        @.Show()
        @.Rotate(30)

    Rotate: (rotation) ->
        rotation = if rotation < 0 then 0 else if rotation > 45 then 45 else rotation
        @.$el.style.transform = 'rotateX(-16deg) rotateY(' + rotation + 'deg) rotateZ(0)'
        @.rotation = rotation

    Show: ->
        @.$el.classList.add 'milkbox--show-faces'
        @.shown = true

BindMilkboxes = ->
    document.querySelectorAll '.milkbox:not(.binded):not(.no-auto-binder)'
        .forEach (el) ->
            window.milkbox = new Milkbox (el)

BindMilkboxes()

window.addEventListener 'dynamicAppendedContent', (e) ->
    BindMilkboxes()