class HTMLModule
    constructor: (el) ->
        @.$el = el
        @.consentGiven = false
        @.consentNeeded = @.$el.classList.contains 'html-module--consent-needed'
        @.codeAppended = !@.consentNeeded
        @.$consentButton = if @.consentNeeded then @.$el.querySelector('.html-module__disclaimer-button') else false
        @.$template = @.$el.querySelector 'template'

        if @.consentNeeded
            @.InitEvents()
        else
            @.$el.classList.add 'html-module--appended'
        @.$el.classList.add 'html-module--binded'
        return

    AppendTemplate: ->
        @.$el.classList.add 'html-module--appended'
        @.codeAppended = true
        @.$el.appendChild document.importNode(@.$template.content, true)
        return

    ConsentGiven: ->
        @.consentGiven = true
        @.$el.classList.add 'html-module--consent-given'
        @.AppendTemplate()
        return

    InitEvents: ->
        self = @
        @.$consentButton.addEventListener 'click', (e) ->
            e.preventDefault()
            self.ConsentGiven()
            return
        return

BindHTMLModules = ->
    document.querySelectorAll '.html-module:not(.html-module--binded)'
        .forEach (el) ->
            new HTMLModule el

BindHTMLModules()

window.addEventListener 'dynamicAppendedContent', (e) ->
    BindHTMLModules()
    return
