Masonry = require "masonry-layout"

class MasonryImages
    constructor: (el) ->
        @.$el = el
        @.$grid = @.$el.querySelector '.masonry-images__grid'

        @.BindMasonry()
        @.$el.classList.add 'masonry-images--binded'

    BindMasonry: ->
        @.masonry = new Masonry( @.$grid, {
            columnWidth: '.masonry-images__grid-sizer',
            gutter: '.masonry-images__grid-gutter',
            itemSelector: '.masonry-images__image',
            percentPosition: true,
            stamp: '.masonry-images__spacer'
        })

BindMasonryImages = ->
    document.querySelectorAll '.masonry-images:not(.masonry-images--binded)'
        .forEach (el) ->
            new MasonryImages el
            return
    return

BindMasonryImages()

window.addEventListener 'dynamicAppendedContent', (e) ->
    BindMasonryImages()
    return