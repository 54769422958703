# +++++ Link Container Class +++++
class LinkContainer
    constructor: (el) ->
        @.$el = el

        @.InitEvents()
        @.$el.classList.add 'binded'
    
    InitEvents: ->

BindLinkContainers = ->
    document.querySelectorAll '.link--container:not(.binded)'
        .forEach (el) ->
            new LinkContainer el

BindLinkContainers()

window.addEventListener 'dynamicAppendedContent', (e) ->
    BindLinkContainers()
