window.appUtils = {}
utils = window.appUtils

# Delete URL Param
utils.DeleteUrlParam = (param, pushState) ->
    url = new URL(window.location.href)
    url.searchParams.delete param
    if pushState
        window.history.pushState document.getElementById('site').innerHTML, document.title, url

# Element in Viewport
utils.ElementInViewport = (el, partiallyVisible = false) ->
    { top, left, bottom, right } = el.getBoundingClientRect()
    { innerHeight, innerWidth } = window
    return if partiallyVisible then ((top > 0 && top < innerHeight) || (bottom > 0 && bottom < innerHeight)) && ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth)) else top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth

# Get URL Parameters
utils.GetUrlParams = ->
    url = window.location.href
    queryStart = url.indexOf("?") + 1
    queryEnd   = url.indexOf("#") + 1 || url.length + 1
    query = url.slice(queryStart, queryEnd - 1)
    pairs = query.replace(/\+/g, " ").split("&")
    parms = {}

    if (query == url || query == "") 
        return

    for i in [0..pairs.length-1]
        nv = pairs[i].split("=", 2)
        n = decodeURIComponent(nv[0])
        v = decodeURIComponent(nv[1])

        if (!parms.hasOwnProperty(n))
            parms[n] = []
        parms[n].push(if nv.length == 2 then v else null)

    return parms

# Push State
utils.PushState = (param,value) ->
    url = new URL(window.location.protocol + "//" + window.location.host + window.location.pathname)
    url.searchParams.append param, value
    url.searchParams.set param, value
    window.history.pushState document.getElementById('site').innerHTML, document.title, url

# SetTabIndex
utils.SetTabIndex = (parent, tabIndex, preSelectedNodeList) ->
    elements = if preSelectedNodeList then parent else Array.from(parent.querySelectorAll('[tabindex]'))
    elements.forEach (el) ->
        el.tabIndex = tabIndex

# ShiftTabIndex
utils.ShiftTabIndex = (parent, shift, preSelectedNodeList) ->
    elements = if preSelectedNodeList then parent else Array.from(parent.querySelectorAll('[tabindex]'))
    elements.forEach (el) ->
        el.tabIndex = parseInt(el.tabIndex,10) + shift

# Unset TabIndex
utils.UnsetTabIndex = (parent, preSelectedNodeList) ->
    elements = if preSelectedNodeList then parent else Array.from(parent.querySelectorAll('[tabindex]'))
    elements.forEach (el) ->
        el.dataset.tabIndex = el.tabIndex
        el.tabIndex = -1

# Random From Range
utils.RandomFromRange = (min, max) ->
    min = Math.ceil min
    max = Math.floor max
    return Math.floor Math.random() * (max - min) + min

# Reset TabIndex
utils.ResetTabIndex = (parent, preSelectedNodeList) ->
    elements = if preSelectedNodeList then parent else Array.from(parent.querySelectorAll('[data-tab-index]'))
    elements.forEach (el) ->
        el.tabIndex = el.dataset.tabIndex
        delete el.dataset.tabIndex

# String to HTML
utils.StringToHtml = (str) ->
    parser = new DOMParser()
    doc = parser.parseFromString str, 'text/html'
    childNodes = doc.body.childNodes
    return if childNodes.length == 1 then childNodes[0] else childNodes

# XML HTTP Request
utils.XMLHttpRequest = (method, url, successFunction, errorFunction) ->
    xmlHttpRequest = new XMLHttpRequest()
    xmlHttpRequest.onreadystatechange = ->
        if xmlHttpRequest.readyState == 4
            if xmlHttpRequest.status == 200
                successFunction()
            else
                if errorFunction
                    errorFunction()
    xmlHttpRequest.open method, url, true
    xmlHttpRequest.send()
    
    return xmlHttpRequest
