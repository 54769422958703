# +++++ Image Class +++++
class Image
    constructor: (el) ->
        @.$el = el
        @.$image = @.$el.querySelector '.image__img'
        @.$imageOverlay = @.$el.querySelector '.image-overlay'
        @.$expandButton = @.$el.querySelector '.image__expand'
        @.expandable = @.$el.classList.contains 'image--expandable'
        @.lazy = @.$el.classList.contains 'image--loading'

        @.InitEvents()
        @.$el.classList.add 'image--binded'

    InitEvents: ->
        self = @
        
        if @.expandable
            @.$expandButton.addEventListener 'click', (e) ->
                imageOverlay = document.importNode(self.$imageOverlay.content,true)
                document.getElementById('site').appendChild imageOverlay
                window.dispatchEvent new CustomEvent('imageOverlayAppended', { bubbles: true })


BindImages = ->
    document.querySelectorAll '.image:not(.image--binded)'
    .forEach (el) ->
        new Image el

BindImages()

window.addEventListener 'dynamicAppendedContent', (e) ->
    BindImages()


# +++++ Image Observer Class +++++
class ImageObserver
    constructor: ->
        @.$lazyImages = []
        @.lazyImageObserver = undefined

        @.RefreshLazyImagesList()
        @.ObserverLazyImages()
    
    ObserverLazyImages: ->
        self = @
        #document.addEventListener 'DOMContentLoaded', (e) ->
        if "IntersectionObserver" of window && "IntersectionObserverEntry" of window && "intersectionRatio" of window.IntersectionObserverEntry.prototype
            if typeof self.lazyImageObserver == "undefined"
                self.lazyImageObserver = new IntersectionObserver((entries, observer) ->
                    entries.forEach (entry) ->
                        if entry.isIntersecting
                            lazyImage = entry.target
                            lazyImage.src = lazyImage.dataset.src
                            lazyImage.classList.remove 'image--lazyload'
                            lazyImage.closest('.image').classList.remove 'image--loading'
                            lazyImage.closest('.image').classList.add 'image--loading-finished'
                            self.lazyImageObserver.unobserve lazyImage.closest('.image')
                )

            self.$lazyImages.forEach (lazyImage) ->
                self.lazyImageObserver.observe lazyImage
        window.addEventListener 'dynamicAppendedContent', (e) ->
            self.RefreshLazyImagesList()
            self.ObserverLazyImages()
    
    RefreshLazyImagesList: ->
        @.$lazyImages = []
        @.$lazyImages = [].slice.call document.querySelectorAll(".image__img.image--lazyload")


window.rsmImageObserver = new ImageObserver()