class VideoModule
    constructor: (el) ->
        @.$el = el
        @.$playButton = @.$el.querySelector '.video-module__play-button'
        @.$video = @.$el.querySelector 'video'
        @.loaded = @.$el.classList.contains 'video-module--loaded'
        @.playing = false

        @.InitEvents()
        @.$el.classList.add 'binded'
    
    InitEvents: ->
        self = @
        if !@.loaded
            @.$playButton.addEventListener 'click', (e) ->
                self.PlayVideo()

        @.$video.addEventListener 'pause', (e) ->
            if !@.seeking
                self.$el.classList.remove 'video-module--playing'
                self.playing = false
            
        window.addEventListener 'stopAllVideos', (e) ->
            self.PauseVideo()

    PauseVideo: ->
        if @.playing
            @.$video.pause()
            @.$el.classList.remove 'video-module--playing'
            @.playing = false
    PlayVideo: ->
        @.$el.classList.add 'video-module--playing'
        @.playing = true
        @.$video.play()

BindVideoModules = ->
    document.querySelectorAll '.video-module:not(.binded)'
        .forEach (el) ->
            new VideoModule el

BindVideoModules()

window.addEventListener 'dynamicAppendedContent', (e) ->
    BindVideoModules()