# +++++ Overlay Class +++++
class Overlay
    constructor: (el) ->
        @.$el = el
        @.$closeButton = @.$el.querySelector '.overlay__closer'
        @.openCloseInterval = undefined
        @.closeDelay = 410
        @.openDelay = 150
        @.closed = false
        @.pageLastScroll = 0

        @.InitEvents()
        @.AppendAndOpenOverlay()

    InitEvents: ->
        self = @
        @.$closeButton.addEventListener 'click', (e) ->
            e.preventDefault()
            self.CloseOverlay()
        window.addEventListener 'closealloverlays', (e) ->
            self.CloseOverlay true
    
    AppendAndOpenOverlay: ->
        # save page last scroll position and fix page
        @.pageLastScroll = window.scrollY
        document.getElementById('site').classList.add 'overlay-open'
        document.getElementById('content').style.transform = "translateY(-" + @.pageLastScroll + "px)"

        # append overlay
        document.getElementById('site').appendChild @.$el
        # shift other tabindex
        window.appUtils.UnsetTabIndex document.querySelectorAll('#site [tabindex]:not(.overlay *)'), true

        window.dispatchEvent new CustomEvent 'dynamicAppendedContent', { bubbles: true }
        window.clearInterval @.openCloseInterval
        self = @
        @.openCloseInterval = window.setInterval () ->
            window.clearInterval self.openCloseInterval
            self.$el.classList.add 'overlay--open'
        , @.openDelay
    
    CloseOverlay: (forced) ->
        if !@.closed
            # set closed
            @.closed = true

            # close overlay and remove element
            @.$el.classList.remove 'overlay--open'
            # reset page scroll
            document.getElementById('site').classList.remove 'overlay-open'
            document.getElementById('content').style.removeProperty "transform"
            window.scrollTo 0, @.pageLastScroll
            # wait for overlay close animation and remove it afterwards
            window.clearInterval @.openCloseInterval
            self = @
            @.openCloseInterval = window.setInterval () ->
                window.clearInterval self.openCloseInterval
                self.$el.remove()
            , @.closeDelay

            # unshift tabindex
            window.appUtils.ResetTabIndex document.getElementById('site')
            
            # delete overlay/offers param
            window.appUtils.DeleteUrlParam 'overlay', true

            # check if close comes not forced e.g. by closealloverlays event -> prevent call stack overflow
            if !forced
                window.dispatchEvent new CustomEvent 'overlayobserverrefresh', { bubbles: true }
            

# +++++ Overlay Observer Class +++++
class OverlayObserver
    constructor: () ->
        @.params = window.appUtils.GetUrlParams()

        @.InitEvents()
        @.CheckForOverlayParam()

    InitEvents: ->
        self = @
        window.addEventListener 'popstate', (e) ->
            self.RefreshParams()
        window.addEventListener 'overlayobserverrefresh', (e) ->
            self.RefreshParams()


    CheckForOverlayParam: ->
        closeAllOverlays = true
        if @.params
            if @.params.hasOwnProperty('overlay')
                if @.params.offer != ""
                    closeAllOverlays = false
                    @.LoadOverlay @.params.overlay
        if closeAllOverlays
            window.dispatchEvent new CustomEvent 'closealloverlays', { bubbles: true }

    LoadOverlay: (overlayId, pushState) ->
        window.dispatchEvent new CustomEvent 'showpageloader', { bubbles: true }
        self = @
        request = window.appUtils.XMLHttpRequest(
            "GET",
            window.location.protocol + "//" + window.location.host + "/overlay/" + overlayId,
            () ->
                window.dispatchEvent new CustomEvent 'hidepageloader', { bubbles: true }
                if request.responseText && request.responseText != ""

                    overlay = window.appUtils.StringToHtml request.responseText
                    new Overlay(overlay)
                    # if overlay load command comes via overlay--opener link
                    if pushState
                        self.PushState overlayId

            () ->
                window.dispatchEvent new CustomEvent 'hidepageloader', { bubbles: true }
                console.log "Es ist ein Fehler aufgetreten. Fehler-Code: " + request.status
        )
    
    PushState: (overlayId) ->
        window.appUtils.PushState 'overlay', overlayId
    
    RefreshParams: ->
        @.params = window.appUtils.GetUrlParams()
        @.CheckForOverlayParam()
 
window.rsmOverlayObserver = new OverlayObserver()

# +++++ Overlay Opener Class +++++
class OverlayOpener
    constructor: (el) ->
        @.$el = el

        @.InitEvents()
        @.$el.classList.add 'binded'

    InitEvents: ->
        self = @
        @.$el.addEventListener 'click', (e) ->
            e.preventDefault()
            @.blur()
            window.rsmOverlayObserver.LoadOverlay self.$el.dataset.overlayId, true

BindOverlayOpener = ->
    document.querySelectorAll '.overlay--opener:not(.binded)'
        .forEach (el) ->
            new OverlayOpener el

BindOverlayOpener()

window.addEventListener 'dynamicAppendedContent', (e) ->
    BindOverlayOpener()
